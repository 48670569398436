import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text, Box } from "insites-ui"

const FAQPage = () => (
  <Layout>
    <SEO title="FAQs" />
    <section className="text-page-section">
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-12 px-0"}>
            <Heading>Frequently Asked Questions</Heading>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="0.5rem 2rem"
            >
              <Heading as="h4" size="sm">
                Is my data protected or secured?
              </Heading>
              <Text>
                Protecting and securing our users’ (and ours given all team
                members use the product personally) is of utmost importance to
                us. We understand the sensitive nature. All data is protected on
                a user account basis where only authenticated users can view
                their personal data. We utilise the Google Cloud Platform (GCP)
                to store our data with Google Authentication services ensuring
                proper security measures have been taken to ensure user data is
                protected. Only raw data is stored in our databases with all
                user-identifying information encrypted. These are all
                enterprise-level tools that have a proven track record for
                providing robust security and used by many Fortune 500
                companies.
              </Text>
              <Heading as="h4" size="sm">
                How do you make money?
              </Heading>
              <Text>
                We currently don’t make any money on the product. In the future,
                we may have some non-invasive advertising WITHOUT selling user
                data. This will likely relate to property services and
                potentially be highly beneficial to our users.
              </Text>
              <Heading as="h4" size="sm">
                Is this free and will it always be free?
              </Heading>
              <Text>
                Our product is currently free and we expect it to stay free in
                the future. Building the platform functionality and capabilities
                is important to us and for this, we do need some revenue to
                sustain development. However, accessibility for all users is
                important to us. In the future, we may discuss with the user
                community on what suits our users best. For now, we would like
                to continue working on our product so can get more value out of
                the platform!
              </Text>
              <Heading as="h4" size="sm">
                Who should use this platform?
              </Heading>
              <Text>
                This platform is designed for all property investors looking for
                a bookkeeping tool with portfolio analysis and forecasting
                features without relying on detailed and confusing excel
                spreadsheets. The tool can be used by new investors with one
                property to seasoned investors with large portfolios of 20+
                properties. It’s designed in a way that the more you use the
                product, and the more data you provide to the tool, the more
                valuable it becomes.
              </Text>
              <Heading as="h4" size="sm">
                How often do I need to track my properties?
              </Heading>
              <Text>
                The platform can be used on a regular basis or as required. We
                recommend updating statistics every fortnight (shouldn’t take
                more than 2-3 minutes) but some investors choose to use the
                platform on a monthly or even 6 monthly basis. The tool’s value
                scales with your use.
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </section>
    {/* <style jsx>{`
      section {
        max-width: 850px;
        margin: 0 auto;
        margin-bottom: 15px;
        padding: 25px;
      }
      @media (min-width: 768px) {
        section {
          margin-bottom: 75px;
        }
      }
      p {
        font-size: 16px;

        font-weight: 400;
      }
      h1 {
        font-weight: 800;
        margin-bottom: 40px;
        margin-top: 20px;
      }
      @media (min-width: 768px) {
        h1 {
          margin-top: 40px;
        }
      }
      h4 {
        margin-bottom: 10px;
        margin-top: 30px;
        font-weight: 600;
      }
    `}</style> */}
  </Layout>
)

export default FAQPage
